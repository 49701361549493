<template>
	<div class="Indexbox">
		<div class="title">
			<p>WELCOME</p>
			<p>欢迎进入百森咨询管理系统</p>
			<img src="/welcome.png" alt="" class="img" />
		</div>
	</div>
</template>
<script>
import * as api from '@/api/workbench';
import { getComInfo } from '@/api/companys';

import { getData } from '@/api/common';
export default {
	data() {
		return {};
	},
	beforeRouteEnter(from, to, next) {
		if (location.hash.split('data=')[1]) {
			getData({ data: location.hash.split('data=')[1] }).then((res) => {
				if (res._responseStatusCode == 0) {
					next((vm) => {
						vm.show = true;
						vm.show1 = true;
						vm.isSuperAdmin = true;
						vm.$route.query = {};
					});
				}
			});
		} else {
			next();
		}
	},
	// beforeRouteEnter(from,to,next){
	//   if(location.hash.split('=')[1]){
	//       getData({data:location.hash.split('=')[1]}).then(res=>{
	//       if(res._responseStatusCode==0){
	//         next()
	//       }
	//     })
	//   }else {
	//     next();
	//   }
	// },
	mounted() {
		// this.$store.dispatch('app/setPermission');
		this.checkLogin();
		// this.getDic();
		getComInfo({ request: {} }).then((res) => {
			if (res) {
				localStorage.setItem('loginImg', JSON.stringify(res));
			}
			if (res.logoImage) {
				const imgurl1 = JSON.parse(res.logoImage);

				document.getElementsByClassName('sidLgogo')[0].src = imgurl1[0].url;
			}
			if (res.smallLogoImage) {
				const imgurl2 = JSON.parse(this.form.smallLogoImage);

				document.getElementsByClassName('smallSidLogo')[0].src = imgurl2[0].url;
			}
		});
	},
	methods: {
		// 查询字典
		getDic() {
			api.getDict({
				request: {}
			})
				.then((res) => {
					this.$store.commit('SET_DISTLIST', res);
					// globalVal.dictList = result;
				})
				.catch(() => {});
		},
		checkLogin() {
			this.$axios.post('/v1/org/quit/config/list', {
				request: {
					pageNo: 1,
					pageSize: 1
				},
				sign: ''
			});
		}
	}
};
</script>
<style lang="scss">
.Indexbox {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 555px;
	.title {
		text-align: center;
		position: relative;
		.img {
			margin: 43px 0 30px;
			width: 63%;
			height: 47%;
			vertical-align: middle;
		}
		p {
			margin-left: 10px;
			// font-family: STCaiyun;
			font-size: 26px;
			color: #888;
		}
		p:first-of-type {
			margin-top: 54px;
			font-family: Times-Roman;
			font-size: 85px;
			color: rgba(119, 126, 140, 0.1);
			letter-spacing: 0;
		}
		p:last-of-type {
			font-family: FZCHSJW--GB1-0;
			font-size: 30px;
			color: #777e8c;
			letter-spacing: 0;
			position: absolute;
			top: 110px;
			left: 50%;
			z-index: 5;
			transform: translateX(-50%);
		}
	}
}
</style>
