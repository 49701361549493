import { post } from "./api";
/**
 * 公司详情
 * @param params
 * @returns {*}
 */
export const getComInfo = (params) => post('/v1/org/company/info', params);
/**
 * 保存
 * @param params
 * @returns {*}
 */
export const save = (params) => post('/v1/org/company/save', params);

/**
 * 保存
 * @param params
 * @returns {*}
 */
export const saveBrand = (params) => post('/v1/org/brand/save', params);

// 公司列表下拉
export function getCompanyOptions (params = {}) {
    return post("/v1/jobfit/plan/result/company", { request: params });
}